export const homeObj1 = {
  id: "quienes-somos",
  linkTo: "services",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "¿Quiénes somos?",
  headLine: "Transportamos tus productos",
  description:
    "Empresa con mas de 20 años de experiencia en el sector de transporte de carga terrestre, especializada en carga contenerizada, que debido a su estrategia enfocada a la excelencia operativa y a la buena comunicacion con el cliente ha logrado un crecimiento en conjunto y la maximizacion de valor de los accionistas y sus colaboradores. ",
  buttonLabel: "Nuestros servicios",
  imgStart: false,
  img: "./images/DSC_0213.jpg",
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObj2 = {
  id: "vision",
  linkTo: "ubiquenos",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Nuestra Visión",
  headLine: "¿Cómo nos proyectamos?",
  description:
    "En L-TRANS SAS queremos ser en el 2025 la mejor alternativa de transporte para nuestros clientes. Siendo una empresa sostenible que genere valor a nuestros colaboradores y accionistas.",
  buttonLabel: "Nuestras ubicaciones",
  imgStart: true,
  img: "./images/IMG_6812.jpg",
  alt: "Piggybank",
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObj3 = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited transactions with zero fees",
  description:
    "Get access to all exclusive app that allows you to send unlimited transactions without getting charged any fees",
  buttonLabel: "Get Started",
  imgStart: false,
  img: "./images/svg-1.svg",
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObj4 = {
  id: "contactenos",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited transactions with zero fees",
  description:
    "Get access to all exclusive app that allows you to send unlimited transactions without getting charged any fees",
  buttonLabel: "Get Started",
  imgStart: false,
  img: "./images/svg-1.svg",
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
